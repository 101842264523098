import { RouterState } from 'connected-react-router';
import * as TenantRedirection from './TenantRedirection';
import * as TenantInformation from './TenantInformation';

export interface ApplicationState {
    tenantRedirection: TenantRedirection.ITenantRedirectionState,
    tenantInformation: TenantInformation.ITenantInformationState,
    router: RouterState
}

export const reducers = {
    tenantRedirection: TenantRedirection.reducer,
    tenantInformation: TenantInformation.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void
}

export interface AsyncAppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): Promise<void>
}