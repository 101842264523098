import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as TenantRedirectionStore from '../store/TenantRedirection';

type TenantRedirectionPageProps =
    {
        requestTenantRedirection: (tenantName: string, deepLink: string) => void;
        tenantRedirection: TenantRedirectionStore.ITenantRedirectionState;
    }
    & RouteComponentProps<{ tenantName: string, deepLink: string }>;

export class TenantRedirectionPage extends React.PureComponent<TenantRedirectionPageProps>
{
    public componentDidMount() {
        const { tenantName } = this.props.match.params;
        this.props.requestTenantRedirection(tenantName.trim().toLowerCase(), this.props.location.search);
    }

    public componentDidUpdate() {
        const { redirectionUrl, error } = this.props.tenantRedirection;

        if (error && 0 < error.trim().length) {
            this.props.history.push('/');
            return;
        }

        if (redirectionUrl && 0 < redirectionUrl.trim().length) {
            window.location.assign(redirectionUrl);
        }
    }

    public render() {
        return (<React.Fragment>
            <h1>Redirecting...</h1>
        </React.Fragment>);
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    tenantRedirection: state.tenantRedirection
});

export default connect(
    mapStateToProps,
    TenantRedirectionStore.actionCreators
)(TenantRedirectionPage);