import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as TenantRedirectionStore from '../store/TenantRedirection';
import * as TenantInformationStore from '../store/TenantInformation';
import { RouteComponentProps } from 'react-router';
import './TenantSelectionPage.min.css';
import unit4ErpLogo from '../assets/img/product/product.svg';
import errorIcon from '../assets/img/exclamation-circle-fill.svg';

type TenantSelectionPageProps = {
    requestTenantRedirection: (tenantName: string, deepLink: string, forceAuthentication: boolean) => void,
    tenantRedirection: TenantRedirectionStore.ITenantRedirectionState,
    requestTenantName: (tenantId: string) => Promise<void>,
    tenantInformation: TenantInformationStore.ITenantInformationState
} & RouteComponentProps;

type TenantSelectionPageState = {
    inputTenantName: string,
    forceAuthentication: boolean
};

export class TenantSelectionPage extends React.PureComponent<TenantSelectionPageProps, TenantSelectionPageState>
{
    readonly currentYear: number;

    constructor(props: TenantSelectionPageProps) {
        super(props);
        this.state = this.initialState;

        this.currentYear = new Date().getFullYear();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    private initialState: TenantSelectionPageState = {
        inputTenantName: '',
        forceAuthentication: false
    };

    private setTenantNameFromQueryParameters() {
        const tenantIdQueryParameter = 'tenantId',
            queryParameters = new URLSearchParams(this.props.location.search);

        const tenantIdQueryValue = queryParameters.get(tenantIdQueryParameter);

        if (tenantIdQueryValue && 0 < tenantIdQueryValue.trim().length) {
            this.props.requestTenantName(tenantIdQueryValue).then(
                () => {
                    const { tenantName } = this.props.tenantInformation;

                    if (tenantName && 0 < tenantName.trim().length) {
                        this.setState({ ...this.state, inputTenantName: tenantName });
                    } else {
                        this.setState({ ...this.state, inputTenantName: '' });
                    }

                    this.props.history.replace({ ...this.props.location, search: '' });
                }
            );
        }
        else {
            this.setState({ ...this.state, inputTenantName: '' });
            this.props.history.replace({ ...this.props.location, search: '' });
        }
    }

    private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            [name]: value
        } as Pick<TenantSelectionPageState, keyof TenantSelectionPageState>);
    }

    private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (this.validateForm()) {
            const tenantName = this.state.inputTenantName.trim().toLowerCase();
            this.props.requestTenantRedirection(tenantName, "", this.state.forceAuthentication);
        }

        this.setState({ ...this.state, inputTenantName: '' });
    }

    private validateForm(): boolean {
        const { inputTenantName } = this.state;

        return !(!inputTenantName || 0 >= inputTenantName.trim().length);
    }

    private renderFormContainer() {
        const { error, isLoading } = this.props.tenantRedirection;

        return (
            <div className="form-container">
                <div className="form-container-wrapper">
                    <div className="form-container-content">
                        <img src={unit4ErpLogo} alt="Unit4 ERP" />
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label className={`input-label ${error ? "input-label-error" : ""}`}>
                                    Tenant name
                            </label>
                                <div className="input-wrapper">
                                    <input
                                        autoFocus={true}
                                        type="text"
                                        name="inputTenantName"
                                        className={`input-text ${error ? "input-text-error" : ""}`}
                                        value={this.state.inputTenantName}
                                        onChange={this.handleInputChange}
                                        maxLength={25} />
                                </div>
                                {error && 0 < error.length && this.renderErrorSection(error)}
                            </div>
                            <div className="checkbox-container">
                                <input
                                    id="forceCheckbox"
                                    name="forceAuthentication"
                                    type="checkbox"
                                    className="input input-checkbox input--small"
                                    checked={this.state.forceAuthentication}
                                    onChange={this.handleInputChange} />
                                <label htmlFor="forceCheckbox">Force authentication</label>
                            </div>
                            <button
                                type="submit"
                                className="btn login-button"
                                onClick={(event) => { event.currentTarget.blur() }}
                                disabled={!this.validateForm() || isLoading}>
                                {isLoading ? "Please wait..." : "Continue"}
                            </button>
                        </form>
                    </div>
                </div>
                <div className="branding-footer">
                    This program is protected by international copyright laws.<br />
                    Copyright &copy; 1987-{this.currentYear} Unit4 Group Holding B.V. and/or its group companies. All rights reserved.&nbsp;
                    <a href="https://www.unit4.com/terms-and-conditions">Privacy policy</a>
                </div>
            </div>
        );
    }

    private renderErrorSection(errorMessage: string) {
        return (
            <div className="input-error">
                <div className="input-error-img"><img src={errorIcon} alt="Error" /></div>
                <span className="input-error-text">{errorMessage}</span>
            </div>
        );
    }

    private dateOffset() {
        const startDate = new Date(1969, 7, 1);
        const today = new Date();
        const diff = Math.abs(today.getTime() - startDate.getTime());
        
        return Math.floor(diff / 86400000); // 1000 * 60 * 60 * 24 : seconds > minutes > hours > days
    }

    private dynamicBackground() {
        let themeNumber = (this.dateOffset() % 3) + 1;

        return "branding-container theme" + themeNumber;
    }

    private dynamicImage() {
        let themeName;
        let days = this.dateOffset();
        let personNumber = (days % 3) + 1;
        let themeNumber = (days % 4) + 1;

        switch (themeNumber) {
            case 1:
                themeName = "highereducation";
                break;
            case 2:
                themeName = "notforprofit";
                break;
            case 3:
                themeName = "professionalservices";
                break;
            default:
                themeName = "publicservices";
                break;
        }
        return themeName + "_0" + personNumber + ".png";
    }

    private renderBrandingContainer() {
        return (
            <div className={this.dynamicBackground()}>
                <div className="branding-body">
                    <div className="branding-header">
                        It's how work should feel...
                    </div>
                    <img className="person" alt="person" src={require(`../FddCdnResources/photos/${this.dynamicImage()}`)} />
                </div>
            </div>
            );
        }

    public componentDidMount() {
        this.setTenantNameFromQueryParameters();
    }

    public componentDidUpdate() {
        const { redirectionUrl } = this.props.tenantRedirection;

        if (redirectionUrl && 0 < redirectionUrl.trim().length) {
            window.location.assign(redirectionUrl);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className="background-container">
                    {this.renderFormContainer()}
                    {this.renderBrandingContainer()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    tenantRedirection: state.tenantRedirection,
    tenantInformation: state.tenantInformation
});

const mapDispatchToProps = {
    requestTenantRedirection: TenantRedirectionStore.actionCreators.requestTenantRedirection,
    requestTenantName: TenantInformationStore.actionCreators.requestTenantName
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TenantSelectionPage);