import { useEffect } from 'react';

export default () => {
  useEffect(() => {
    
    fetch('static/media/src/assets/img/product/product.svg')
      .then(response => {
        
        let headerValue = response.headers.get('x-u4-app-title');
        
        if (headerValue) {
          document.title = headerValue;
        }
        

      });
  }, []);

  return null;
};