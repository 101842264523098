import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface ITenantRedirectionState {
    isLoading: boolean;
    tenantName: string;
    redirectionUrl?: string;
    error?: string;
}

const unloadedState: ITenantRedirectionState = {
    isLoading: false,
    tenantName: '',
    redirectionUrl: '',
    error: ''
};

function generateRedirectionUrl(responseData: string, forceAuthentication: boolean) {
    const responseQueryParameters = forceAuthentication ? `${PROMPT_QUERY_PARAMETER}` : '';
    const queryCharacter = responseData.includes('?') ? '&' : '?';

    return forceAuthentication ? `${responseData}${queryCharacter}${responseQueryParameters}` : `${responseData}`;
}

export const PROMPT_QUERY_PARAMETER = 'prompt';

const REQUEST_TENANT_REDIRECTION_URL = 'REQUEST_TENANT_REDIRECTION_URL';
const RECEIVE_TENANT_REDIRECTION_URL = 'RECEIVE_TENANT_REDIRECTION_URL';
const RECEIVE_TENANT_REDIRECTION_ERROR = 'RECEIVE_TENANT_REDIRECTION_ERROR';

interface IRequestTenantRedirectionAction {
    type: typeof REQUEST_TENANT_REDIRECTION_URL;
    tenantName: string;
}

interface IReceiveTenantRedirectionAction {
    type: typeof RECEIVE_TENANT_REDIRECTION_URL;
    tenantName: string;
    redirectionUrl: string;
}

interface IReceiveTenantRedirectionErrorAction {
    type: typeof RECEIVE_TENANT_REDIRECTION_ERROR;
    tenantName: string;
    error: string;
}

export type KnownAction = IRequestTenantRedirectionAction
    | IReceiveTenantRedirectionAction
    | IReceiveTenantRedirectionErrorAction;

export const actionCreators = {
    requestTenantRedirection: (tenantName: string, deepLink: string, forceAuthentication = false): AppThunkAction<KnownAction> =>
        async (dispatch, getState): Promise<void> => {
            const appState = getState();

            if (appState && appState.tenantRedirection && tenantName !== appState.tenantRedirection.tenantName) {
                dispatch({ type: REQUEST_TENANT_REDIRECTION_URL, tenantName: tenantName });
                try {
                    const url = (deepLink.trim().length > 0) ? `api/${tenantName}/${deepLink}` : `api/${tenantName}`;
                    const response = await fetch(url);
                    const responseData = await response.text();

                    if (!response.ok) {
                        dispatch({ type: RECEIVE_TENANT_REDIRECTION_ERROR, tenantName: tenantName, error: responseData });
                    } else {
                        dispatch({
                            type: RECEIVE_TENANT_REDIRECTION_URL,
                            tenantName: tenantName,
                            redirectionUrl: generateRedirectionUrl(responseData, forceAuthentication)
                        });
                    }
                } catch {
                    dispatch({ type: RECEIVE_TENANT_REDIRECTION_ERROR, tenantName: tenantName, error: 'There was an error processing your request. Please try again.' });
                }
            }
        }
};

export const reducer: Reducer<ITenantRedirectionState> =
    (state: ITenantRedirectionState | undefined, incomingAction: Action): ITenantRedirectionState => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case REQUEST_TENANT_REDIRECTION_URL:
                return {
                    isLoading: true,
                    tenantName: action.tenantName
                };
            case RECEIVE_TENANT_REDIRECTION_ERROR:
                return {
                    isLoading: false,
                    tenantName: action.tenantName,
                    error: action.error
                };
            case RECEIVE_TENANT_REDIRECTION_URL:
                return {
                    isLoading: true,
                    tenantName: action.tenantName,
                    redirectionUrl: action.redirectionUrl
                };
            default:
                return state;
        }
    };