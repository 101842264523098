import { Action, Reducer } from 'redux';
import { AsyncAppThunkAction } from '.';

export interface ITenantInformationState {
    tenantId: string;
    tenantName?: string;
}

const unloadedState: ITenantInformationState = {
    tenantId: '',
    tenantName: ''
};

const REQUEST_TENANT_NAME = 'REQUEST_TENANT_NAME';
const RECEIVE_TENANT_NAME = 'RECEIVE_TENANT_NAME';

interface IRequestTenantNameAction {
    type: typeof REQUEST_TENANT_NAME,
    tenantId: string
}

interface IReceiveTenantNameAction {
    type: typeof RECEIVE_TENANT_NAME,
    tenantId: string,
    tenantName: string
}


export type KnownAction = IRequestTenantNameAction
    | IReceiveTenantNameAction;

export const actionCreators = {
    requestTenantName: (tenantId: string): AsyncAppThunkAction<KnownAction> =>
        async (dispatch): Promise<void> => {
            dispatch({ type: REQUEST_TENANT_NAME, tenantId: tenantId });
            try {
                const response = await fetch(`api/tenant/${tenantId}`);
                if (!response.ok) {
                    dispatch({ type: RECEIVE_TENANT_NAME, tenantId: tenantId, tenantName: '' });
                } else {
                    dispatch({ type: RECEIVE_TENANT_NAME, tenantId: tenantId, tenantName: await response.text() });
                }
            } catch {
                dispatch({ type: RECEIVE_TENANT_NAME, tenantId: tenantId, tenantName: '' });
            }
        }
};

export const reducer: Reducer<ITenantInformationState> =
    (state: ITenantInformationState | undefined, incomingAction: Action): ITenantInformationState => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case REQUEST_TENANT_NAME:
                return {
                    tenantId: action.tenantId
                };
            case RECEIVE_TENANT_NAME:
                return {
                    tenantId: action.tenantId,
                    tenantName: action.tenantName
                };
            default:
                return state;
        }
    };