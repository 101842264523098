import * as React from 'react';
import { Route, Switch } from 'react-router';
import TenantSelectionPage from './components/TenantSelectionPage';
import TenantRedirectionPage from './components/TenantRedirectionPage';
import './custom.css'

export default () => (
    <div>
        <Switch>
            <Route exact path='/fddhealth' />
            <Route exact path='/fddprobe' />
            <Route exact path='/' component={TenantSelectionPage} />
            <Route exact path='/:tenantName*' component={TenantRedirectionPage} />
        </Switch>
    </div>
);
